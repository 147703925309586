// import { CUSTOMER_VIEW, MEMBER_VIEW } from '../../constants/menuConstants';
import { getpreference, updatepreference } from '../../urlConstants';
import restUtils from '../../utils/restUtils';

export const GET_ALL_PREFERENCE = 'GET_ALL_PREFERENCE';
export const UPDATE_PREFERENCE = 'UPDATE_PREFERENCE';
export const UPDATE_PRODUCT_LIST_VIEW_PREFERENCE =
  'UPDATE_PRODUCT_LIST_VIEW_PREFERENCE';
  export const MEMBER_VIEW = 'Member View';
export const CUSTOMER_VIEW = 'Customer View';

export const getPreferences = (username, storeid) => {
  return dispatch => {
    const postInput = {
      userId: username,
      storeId: storeid
    };
    const url = `${getpreference}`;
    restUtils
      .postData(url, postInput)

      .then(response => {
        if(!localStorage.getItem('viewType')) {
          localStorage.setItem('viewType', response.data.viewType === 'CV' ? CUSTOMER_VIEW : MEMBER_VIEW);
        }        
        return dispatch({
          type: GET_ALL_PREFERENCE,
          payload: response.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const updatepreferences = (
  username,
  storeId,
  cost,
  image,
  warehouse,
  page,
  view,
  vendor,
  orderpad,
  filters,
  specify,
  viewTypes,
  cursorPlacement
) => {
  return dispatch => {
    const preferInput = {
      userId: username,
      storeId: storeId,
      customerView: cost,
      thumbnailImage: image,
      warehouseProducts: warehouse,
      resultPerPage: page,
      prdctListlayoutFormat: view,
      vendorPrdctLayoutFmt: vendor,
      overrideRetail: orderpad,
      filterView: filters,
      productSpecView: specify,
      viewType:viewTypes,
      cursorPlacement: cursorPlacement
    };
    const insertPreference = `${updatepreference}`;
    restUtils
      .postData(insertPreference, preferInput)
      .then(response => {
        localStorage.setItem('viewType', viewTypes === 'CV' ? CUSTOMER_VIEW : MEMBER_VIEW);
        if (response.data === 'Preferences Updated') {
          return dispatch({
            type: UPDATE_PREFERENCE,
            payload: preferInput
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const updateProductListViewPreference = preference => {
  return dispatch => {
    return dispatch({
      type: UPDATE_PRODUCT_LIST_VIEW_PREFERENCE,
      payload: preference
    });
  };
};

import React from 'react'
import {withRouter} from 'react-router'
import { GlobalHotKeys } from "react-hotkeys";
import {keyMap} from '../views/common/constants'

const handlers = {
  SCROLL_TO_TOP: (event)=>{
    window.scrollTo(0, 0);
  }
}

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
      }
    }
    componentDidMount(){
        window.scrollTo(0,0);
    }
  
    render() {
      return (
        <GlobalHotKeys keyMap= {keyMap} handlers = {handlers}>{this.props.children}</GlobalHotKeys>
      )
    }
}

export default withRouter(ScrollToTop);

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store, { persistor } from './configureStore';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import './assets/fontawesome/css/all.min.css';

render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);



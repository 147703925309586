import { REUNION_APP, REUNION_ORDERING, ADMIN } from '../views/common/constants';
import { deals } from '../urlConstants';
const CORPORATE_EBOOK = 'E-Books';
export const productsMenu = {
  departments: {
    key: 'departments',
    title: 'Departments',
    isMenu: true
  },
  events: {
    key: 'wholeSaleDeals$/wholeSaleDeals',
    title: 'Wholesale Deals',
    isMenu: true,
    dealsOfTheMonth: {
      key: 'retailEvents$/retail-events',
      title: 'Retail Events',
      isMenu: false
    },
    monthlySpecials: {
      key: 'monthlySpecials$/monthlySpecials',
      title: 'Monthly Specials',
      isMenu: false
    },
    hotDeals: {
      key: 'hotDeals$/listing/HotDeals',
      title: deals,
      isMenu: false
    }, 
    // flashSales: {
    //   key: 'flashSales$/flashSales',
    //   title: 'Flash Sales',
    //   isMenu: false
    // }, 
    closeoutCorner: {
      key: 'closeoutCorner$/closeoutCorner',
      title: 'Closeout Corner',
      isMenu: false
    }
  },
  newItems: {
    key: 'newItems$/listing/NewItems',
    title: 'New Items',
    isMenu: false
  },
  discontinuedItems: {
    key: 'discontinued$/listing/Discontinued',
    title: 'Discontinued Items',
    isMenu: false
  },
  closeoutItems: {
    key: 'closeoutItems$/listing/CloseoutItems',
    title: 'Closeout Items',
    isMenu: false
  },
  retailAssortments: {
    key: 'retailAssortments',
    title: 'Retail Assortments',
    isAssortment: 'retailAssortments',
    isMenu: true
  },
  ctbAssortments: {
    key: 'ctbAssortments',
    title: 'CTB Assortments',
    isAssortment: 'ctbAssortments',
    isMenu: true
  },
  planograms: {
    key: 'planograms',
    title: 'Planograms',
    isMenu: true
  },
  /*impulseMerchandising: {
    key: 'impulseMerchandising$/impulse-merchandising',
    title: 'Impulse Merchandising',
    isMenu: false
  }*/
};

export const ordersMenu = {
  allOrders: {
    key: 'allorders$/allorders',
    title: 'Order Status',
    isMenu: false
  },
  orderTools: {
    key: 'orderTools',
    title: 'Order Tools',
    isMenu: true,
    orderPad: {
      key: 'orderPad$/orderpad',
      title: 'Order Pad',
      isMenu: false
    },
   /* cipherUpload: {
      key: 'orderUpload$/orderupload',
      title: 'Order Upload (Excel / Cipher)',
      isMenu: false
    },*/
    orderUpload: {
      key: 'orderUpload$/orderupload',
      title: 'Order Upload',
      isMenu: false
    },
    ltlFreight: {
      key: 'ltlFreight$/ltlfreight',
      title: 'LTL Freight Estimator',
      isMenu: false
    }
  },
  orderEdit: {
    key: 'orderEdit',
    title: 'Order Maintenance',
    isMenu: true,
    shipLater: {
      key: 'shipLater$/shipLaters',
      title: 'Ship Laters',
      isMenu: false
    },
    editOrders: {
      key: 'editOrders$/editOrders',
      title: 'Edit Orders',
      isMenu: false
    },
    promoShip: {
      key: 'autoShip$/autoShip',
      title: 'Auto-Ship',
      isMenu: false
    }
  },
  orderReport: {
    key: 'orderReport',
    title: 'Order Reports',
    isMenu: true,
    cancelItems: {
      key: 'cancelItems$/cancelledItems',
      title: 'Cancelled Items',
      isMenu: false
    },
    fillRates: {
      key: 'fillRates$/fillRates',
      title: 'Fill Rates',
      isMenu: false
    },
    ctbAssortmentOrders: {
      key: 'ctbAssortmentOrders',
      title: 'CTB NW Order Report',
      isMenu: false
    }
  }
};

export const adminMenu = {
  HomeTiles: {
    key: 'shoppingArea$/admin/HomeTilesConfig',
    title: 'Home Tiles Config',
    isMenu: false
  },
  shoppingArea: {
    key: 'shoppingArea$/admin/shoppingArea',
    title: 'Shopping Area Definition',
    isMenu: false
  },
  PromoLinking: {
    key: 'shoppingArea$/admin/promoLinking',
    title: 'Promo Linking',
    isMenu: false
  },
  limitQtyBB: {
    key: 'shoppingArea$/admin/limitQuantity/BargainBurners',
    title: 'Limit Quantity (Bargain Burners)',
    isMenu: false
  },
  limitQtyWow: {
    key: 'shoppingArea$/admin/limitQuantity/Wow',
    title: 'Limit Quantity (WOW)',
    isMenu: false
  },
  RARestriction: {
    key: 'shoppingArea$/admin/RARestrictions',
    title: 'RA Restrictions',
    isMenu: false
  },
  dropShipReport:{
    key: 'shoppingArea$/admin/dropShip',
    title: 'Dropship Report',
    isMenu: false
  },
  retailAssortments: {
    key: 'shoppingArea$/admin/MenuOptions',
    title: 'Menu Option(s)',
    isMenu: false
  }
};

export const mainMenu = {
  home: { key: 'home$/home', title: 'Home', isMenu: false },
  products: {
    key: 'products',
    title: 'Products',
    isMenu: true,
    ...productsMenu
  },
  orders: {
    key: 'orders',
    title: 'Orders',
    isMenu: true,
    ...ordersMenu
  },
  vendors: { key: 'vendors$/vendors', title: 'Vendors A-Z', isMenu: false },
  favorites: {
    key: 'favorites',
    title: 'Favorites',
    isMenu: true,
    favoriteItems: {
      key: 'favorites$/favorites',
      title: 'Favorite Items',
      isMenu: false
    },
    favoriteVendors: {
      key: 'favoriteVendors$/favoriteVendors',
      title: 'Favorite Vendors',
      isMenu: false
    }
  },
  finelineCatalog: {
    key: 'finelineCatalog',
    title: 'Fineline Catalog',
    isMenu: true,
    catalog: {
      key: 'finelineCatalog$/finelineCatalog',
      title: 'Catalogs',
      isMenu: false,
    },
    adConfiguration: {
      key: 'finelineCatalog$/finelineCatalog/ad-configuration',
      title: 'Ad Configuration',
      isMenu: false,
    }
  },
  /*reunionApp: {
      key: 'reunionApp$/reunionApp',
      title: REUNION_APP,
      isMenu: false
  },
  reunion: {
      key: 'reunion',
      title : 'Reunion',
      isMenu: true,
      reunionEvent: {
        key: 'reunionEvent$/reunionEvent',
        title: 'Reunion Events',
        isMenu: false
      },
      reunionAssortment: {
        key: 'reunionAssortment$/reunionAssortment',
        title: 'Reunion Retail Assortments',
        isMenu: false
      },
      discountCity: {
        key: 'discountCity$/discountCity',
        title: 'Discount City',
        isMenu: false
      },
      wow: {
        key: 'wow$/wow',
        title: 'WOW',
        isMenu: false
      },
      barginBurners: {
        key: 'barginBurners$/barginBurners',
        title: 'Bargain Burners',
        isMenu: false
      },
      impluseAlley: {
        key: 'impluseAlley$/impluseAlley',
        title: 'Impluse Alley',
        isMenu: false
      },
      clouseoutConer: {
        key: 'clouseoutConer$/clouseoutConer',
        title: 'Closeout Corner',
        isMenu: false
      },
      openStock: {
        key: 'openStock$/openStock',
        title: 'Open Stock',
      isMenu: false
      },
      CTBs: {
        key: 'CTBs$/CTBs',
        title: 'CTBs',
        isMenu: false
      },
      adHocEvent: {
        key: 'adHocEvent$/adHocEvent',
        title: 'Ad-Hoc Events',
        isMenu: false
      }
  },*/
  reunionOrdering: {
      key: 'reunionOrdering$/reunionOrdering',
      title : REUNION_ORDERING,
      isMenu: false
  }, 
  addressBook: {key:'adddressEbook$/AddressBook', title:'Address Book', isMenu: false},
  corporateEbook: { key: 'corporateEbook$/corporateEbook', title: CORPORATE_EBOOK, isMenu: false }, 
  admin: {
    key: 'admin',
    title: ADMIN,
    isMenu: true,
    ...adminMenu
  },
  NeedHelp: {
    key: 'NeedHelp',
    title: 'Need Help?',
    isMenu: true,
    learnNewWarehouse: {
      key:"learnNewWarehouse$/https://aem.membersonline.com/content/MOL/product/NewNetwarehouseTraining.html",
      title:"Learn New Netwarehouse"
    },
    ordering: {
      key: "help-ordering",
      title: 'Ordering',
      isMenu: true,
      warehouseOrders: {
        key: "help-warehouseOrders",
        title: 'Creating Warehouse Orders',
        isMenu: false
      },
      navigatingtheordercart: {
        key: "help-manageMultiCarts",
        title: 'Navigating the Order Cart ',
        isMenu: false
      
      },
      SubmittingOrders: {
        key: "help-submitOrder",
        title: 'Submitting Orders ',
        isMenu: false
      
      },
      Wholesaledeals: {
        key: "help-hotdeals",
        title: 'Wholesale Deals ',
        isMenu: false
      
      },
    },
    productNavigation: { 
      key: "help-productNavigation",
      title: 'Product Navigation',
      isMenu: true,
      easierProductSearching: {
        key: "help-usingSearch",
        title: 'Easier Product Searching',
        isMenu: false
      },
      productfiltering: {
        key: "help-usingFilters",
        title: 'Product Filtering',
        isMenu: false
      },
      itemquickview: {
        key: "help-quickView",
        title: 'Item Quick View',
        isMenu: false
      },
      compareproducts: {
        key: "help-compareProducts",
        title: 'Compare Products',
        isMenu: false
      },
      addingfavoriteproducts: {
        key: "help-favoriteProdEasyOrder",
        title: 'Adding Favorite Products',
        isMenu: false
      },
      addingfavoritevendors: {
        key: "help-favoriteVendors",
        title: 'Adding Favorite Vendors',
        isMenu: false
      },
      newitemcarousel: {
        key: "help-newItemsCarousel",
        title: 'New Item Carousel',
        isMenu: false
      },
      Viewcompetitorpricing: {
        key: "help-viewCompetitor",
        title: 'View Competitor Pricing',
        isMenu: false
      },
      alternateSKU: {
        key: "help-alternateSKU",
        title: 'Recommended Alternative SKU',
        isMenu: false
      },
      exporttoexcel: {
        key: "help-exportingToExcel",
        title: 'Export to Excel',
        isMenu: false
      },
    },
    assortmentsAndPlanograms: {
      key: "help-assortmentsAndPlanograms",
      title: 'Assortments and Planograms',
      isMenu: true,
      retailAssortments: {
        key: "help-retailAssortments",
        title: 'Retail Assortments',
        isMenu: false
      },
      planograms: {
        key: "help-planograms",
        title: 'Planograms',
        isMenu: false
      },
      impulseMerchandising: {
        key: "help-impulseMerchandise",
        title: 'Impulse Merchandising Ordering',
        isMenu: false
      },
    },
    reunionEventOrdering: {
      key: "help-reunionEventOrdering",
      title: 'Reunion Event Ordering',
      isMenu: true,
    bargainBurners: {
      key: "help-bargainBurners",
      title: 'Bargain Burners',
      isMenu: false
    },
    discountCity: {
      key: "help-discountCity",
      title: 'Discount City',
      isMenu: false
    },
    dropshipdiscountCity: {
      key: "help-dropshipdiscountCity",
      title: 'Drop Ship Discount City',
      isMenu: false
    },
    reunionimpulsealley: {
      key: "help-reunionimpulsealley",
      title: 'Impulse Alley',
      isMenu: false
    },
    newitemNeightborhood: {
      key: "help-newitemNeightborhood",
      title: 'New Item Neighborhood',
      isMenu: false
    },
    reunionretailassortments: {
      key: "help-reunionretailassortments",
      title: 'Reunion Retail Assortments',
      isMenu: false
    },
    vendorDropShip: {
      key: "help-vendorDropShip",
      title: 'Vendor Drop Ship',
      isMenu: false
    },
    deptEventsNewItemNeighbor: {
      key: "help-deptEventsNewItemNeighbor",
      title: 'Warehouse Dept Events/New Item Neighborhood',
      isMenu: false
    },
    Wow: {
      key: "help-wow",
      title: 'Wow',
      isMenu: false
      },
      
    },
    // bargainBurners: {
    //   key: "help-bargainBurners",
    //   title: 'Bargain Burners',
    //   isMenu: false
    // },
    // discountCity: {
    //   key: 'help-discountCity',
    //   title: 'Discount City',
    //   isMenu: false
    // },
    // dropshipdiscountCity: {
    //   key: 'help-dropshipdiscountCity',
    //   title: 'Drop Ship Discount City',
    //   isMenu: false
    // },
    // reunionimpulsealley: {
    //   key: 'help-reunionimpulsealley',
    //   title: 'Impulse Alley',
    //   isMenu: false
    // },
    // newitemNeightborhood: {
    //   key: 'help-newitemNeightborhood',
    //   title: 'New Item Neighborhood',
    //   isMenu: false
    // },
    // reunionretailassortments: {
    //   key: 'help-reunionretailassortments',
    //   title: 'Reunion Retail Assortments',
    //   isMenu: false
    // },
    // vendorDropShip: {
    //   key: 'help-vendorDropShip',
    //   title: 'Vendor Drop Ship',
    //   isMenu: false
    // },
    // deptEventsNewItemNeighbor: {
    //   key: 'help-deptEventsNewItemNeighbor',
    //   title: 'Warehouse Dept Events/New Item Neighborhood',
    //   isMenu: false
    // },
    //warehouseOrders: {
      //key: 'help-warehouseOrders',
     // title: 'Warehouse Deals',
      //isMenu: false
    //},
    //hotdeals: {
      //key: 'help-hotdeals',
      //title: 'Wholesale Deals/Hot Deals',
      //isMenu: false
    //},
    // wow: {
    //   key: 'help-wow',
    //   title: 'WOW',
    //   isMenu: false
    // },
   /* submitOrder: {
      key: 'help-submitOrder',
      title: 'Submitting Orders',
      isMenu: false
    },
    compareProducts: {
      key: 'help-compareProducts',
      title: 'Compare Products',
      isMenu: false
    },
    exportingToExcel: {
      key: 'help-exportingToExcel',
      title: 'Exporting to Excel',
      isMenu: false
    },
    favoriteProdEasyOrder: {
      key: 'help-favoriteProdEasyOrder',
      title: 'Favorite Products and Easy Reorder',
      isMenu: false
    },
    favoriteVendors: {
      key: 'help-favoriteVendors',
      title: 'Favorite Vendors',
      isMenu: false
    },
    impulseMerchandise: {
      key: 'help-impulseMerchandise',
      title: 'Impulse Merchandise',
      isMenu: false
    },
    quickView: {
      key: 'help-quickView',
      title: 'Item Quickview',
      isMenu: false
    },
    manageMultiCarts: {
      key: 'help-manageMultiCarts',
      title: 'Navigating the Order Cart',
      isMenu: false
    },
    newItemsCarousel: {
      key: 'help-newItemsCarousel',
      title: 'New Item Carousel',
      isMenu: false
    },
    planograms: {
      key: 'help-planograms',
      title: 'Planograms',
      isMenu: false
    },
    retailAssortments: {
      key: 'help-retailAssortments',
      title: 'Retail Assortments',
      isMenu: false
    },
    usingFilters: {
      key: 'help-usingFilters',
      title: 'Using Filters',
      isMenu: false
    },
    usingSearch: {
      key: 'help-usingSearch',
      title: 'Using Search',
      isMenu: false
    },
    viewCompetitor: {
      key: 'help-viewCompetitor',
      title: 'Viewing Competitor Pricing',
      isMenu: false
    },*/
  }, 
};

export const videoLinks = {
  bargainBurners: "https://youtu.be/oHGf_MhLZic",
  compareProducts: "https://youtu.be/K4jad8UMXEA",
  deptEventsNewItemNeighbor: "https://youtu.be/Q2MVw78kFmI",
  exportingToExcel: "https://youtu.be/AK6zN6BDS3M",
  favoriteProdEasyOrder: "https://youtu.be/w52FBlSY_7o",
  favoriteVendors: "https://youtu.be/AIb84VsClmc",
  feedbackChannel: "https://youtu.be/XPuYGV6LY9Q",
  impulseMerchandise: "https://youtu.be/9Y5SBnH0GNI",
  quickView: "https://youtu.be/RijXrzSxL6Q",
  manageMultiCarts: "https://youtu.be/ugypOyMHNmk",
  newItemsCarousel: "https://youtu.be/5ARIgaPB760",
  planograms: "https://youtu.be/Zg5_WPHJtUY",
  retailAssortments: "https://youtu.be/a-wRK5rgLRQ",
  submitOrder: "https://youtu.be/LQ9qbeTDlX8",
  usingFilters: "https://youtu.be/V5xuwU7SGXo",
  usingSearch: "https://youtu.be/YkjmHXmw5_4",
  viewCompetitor: "https://youtu.be/QbGYJzug6J4",
  hotdeals: "https://youtu.be/AutN6hzNAjU",
  warehouseOrders: "https://youtu.be/gPRIMj97aJo",
  wow: "https://youtu.be/-fP6cfyn130",
  discountCity: "https://youtu.be/AdZIaElSJBw",
  newitemNeightborhood: "https://youtu.be/WHtDi-EVGtE",
  dropshipdiscountCity: "https://youtu.be/GzNSmq5FY1w",
  reunionimpulsealley: "https://youtu.be/vMpM-aG5m0o",
  reunionretailassortments: "https://youtu.be/zkKyX3SD0YQ",
  vendorDropShip: "https://youtu.be/F0ZR6rJ5P9U",
  alternateSKU: "https://youtu.be/_9xRSPhAFWQ"
}

const { home, products, orders, vendors, favorites, reunion, reunionOrdering, finelineCatalog } = mainMenu;
export const urlToMenuKeyMapper = {
  home: home.key,
  listing: products.key,
  categories: products.key,
  'deals-of-the-month': products.key,
  retailAssortments: products.key,
  planograms: products.key,
  orderpad: orders.key,
  orderupload: orders.key,
  allorders: orders.key,
  vendors: vendors.key,
  favorites: favorites.key,
  favoriteVendors: favorites.key,
  finelineCatalog: finelineCatalog.key,
  reunionOrdering : reunionOrdering.key,
  reunionEvent : reunionOrdering.key,
  finelineCatalog: finelineCatalog.key
};

// View Constants
export const MEMBER_VIEW = 'Member View';
export const CUSTOMER_VIEW = 'Customer View';
export const NOT_POG_VIEW = ['departments','newItems','discontinuedItems','closeoutItems','events'];
export const VIEW_ACCESS = ['departments','newItems','discontinuedItems','closeoutItems','events','retailAssortments','planograms','impulseMerchandising'];
export const DEPARTMENT_ACCESS = ['newItems','discontinuedItems','closeoutItems','events','retailAssortments','planograms','impulseMerchandising'];
export const VENDFAVACCESS=['vendors', 'favorites'];
export const FINELINE_CATALOG_TITLE = 'Fineline Catalog';


import { createStore, applyMiddleware } from "redux";
import {
  persistCombineReducers,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { reducer as toastr } from "react-redux-toastr";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import config from "./config/config";

import SessionReducer from "./views/Login/reducer";

import UtilityReducer from "./utils/reducer";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: [
    "navbar",
    "search",
    "toastr",
    "categories",
    "products",
    "vendors",
    "reviews",
    "variations",
    "cart",
    "finelineCatalog",
  ],
};

const rootReducer = persistCombineReducers(rootPersistConfig, {
  toastr,

  SessionReducer: persistReducer({ key: "session", storage }, SessionReducer),

  UtilityReducer: persistReducer(
    {
      key: "utility",
      storage,
    },
    UtilityReducer
  ),
});

const history = createBrowserHistory();
export const store = createStore(
  rootReducer,
  undefined,
  composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history)))
);

export const persistor = persistStore(store);

export { history };
export default store;

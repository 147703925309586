import React from "react";
import Axios from "axios";
import FileSaver from "file-saver";
import { Loader, Pagination } from "semantic-ui-react";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";

import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import restUtils from "../../utils/restUtils";
import "./AllOrders.css";
import utilities from "../../utils/utilities";
import { getMRIDocuments } from "../Login/actions";
import {
  downloadMRIDocumentUri,
  getMRIDocumentsUri,
} from "../../../src/urlConstants";
import { getRaCategories } from "./actions";
import DownloadReport from "../images/print.png";
import { TOKEN } from "../common/constants";
import search_img from "../images/Search.svg";
import close_btn from "../images/close_btn.png";
import refresh_icon from "../images/refresh_icon.png";
import renderImages from "../../views/common/RenderImages";
const jssha = require("js-sha256");
class AllOrders extends React.Component {
  constructor(props) {
    super(props);
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    this.handlePopState = this.handlePopState.bind(this);
    this.handleHashChange = this.handleHashChange.bind(this);
    this.state = {
      allOrders: [],
      currentPages: [],
      loading: false,
      serverError: false,
      noOfOrders: 0,
      scrollPostion: 0,
      firstItem: 0,
      lastItem: 0,
      currentPage: 1,
      totalPages: 0,
      filterForRequest: {},
      showItemPerPage: 50,
      searchInput: "",
      sortParam: "orderDate",
      sortDirection: "desc",
      searchForDate: "",
      searchFieldError: false,
      filterType:"none"
    };
    this.scrollPosition = 0;
  }
  onPageChange = (e) => {
    let queryPage = e.currentTarget.text;

    if (e.currentTarget.text === "Next") {
      queryPage =
        this.state.currentPage < this.state.totalPages
          ? parseInt(this.state.currentPage) + 1
          : this.state.currentPage;
    }
    if (e.currentTarget.text === "Prev") {
      queryPage =
        this.state.currentPage === 1 ? 1 : parseInt(this.state.currentPage) - 1;
    }
    if (e.currentTarget.text === "»") {
      queryPage = this.state.totalPages;
    }
    if (e.currentTarget.text === "«") {
      queryPage = 1;
    }
    this.setState({ currentPage: queryPage });

    this.page = queryPage;
    this.scrollPosition = window.pageYOffset;
    window.scrollTo(0, 0);
  };
  onDateChange = (date) => {
    this.setState({ searchForDate: date });
  };
  convertDate = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  componentDidCatch = () => {
    this.getOrders();
  };
  handleBeforeUnload = () => {
      debugger;
    localStorage.clear();
     window.history.forward();
    }
  componentDidMount = () => {
    this.getOrders();  
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    window.addEventListener('popstate', this.handlePopState);
    window.addEventListener('hashchange', this.handleHashChange);
  };    
   componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    window.removeEventListener('popstate', this.handlePopState);
    window.removeEventListener('hashchange', this.handleHashChange);
  }

  handlePopState = () => {
    debugger; // Add a debugger to troubleshoot the issue
    localStorage.clear();
     window.history.forward();
  }

  handleHashChange = () => { 
    debugger; // Add a debugger to troubleshoot the issue
    localStorage.clear();
  }
 
  componentDidUpdate(preProps, preState) {
    const { getMRIDocumentList } = this.props;

    try {
      if (preProps.getMRIDocumentList !== this.props.getMRIDocumentList) {
        this.setPagination(getMRIDocumentList);
      }

      if (preState.showItemPerPage !== this.state.showItemPerPage) {
        this.setPagination(getMRIDocumentList);
      }
    } catch (error) {
      this.setState({ loading: false });
      utilities.showToast(error, true);
    }
  }
  setPagination = (data) => {
    const { showItemPerPage } = this.state;
    const { mRIMetaDataList, pageCount, totalCount } = data;
    
    this.setState({
      allRecipts: mRIMetaDataList,
      loading: false,
      noOfOrders: totalCount,
      serverError: false,
      totalPages: pageCount,
    });
  };
  getOrders = async () => {
    const {
      showItemPerPage,
      currentPage,
      sortDirection,
      filterType,
      filterValues,
    } = this.state;
    this.setState({ loading: true });

    const member = this.props.storeId;

    const config = {
      rowsPerPage: showItemPerPage,
      pageNbr: currentPage,
      sortColumn: 1,
      sortOrder: sortDirection,
      filterType: filterType,
      filterValues: filterValues,
    };
    await this.props.getMRIDocuments(member, config);
    this.setState({ loading: false });
   
  };
  hash = (input) => {
    const hashString = "0x" + jssha.sha256(input);
    return hashString;
  };
  download_MRI_PDF = async (id) => {
    this.setState({ loading: true });

    const member = this.props.storeId;
    const url = `${downloadMRIDocumentUri()}/${id}/Member/${member}`;
    await restUtils
      .getDataMRI(url)
      .then(async (response) => {
        const rec_url = response.data;

        const urlWithoutHash = rec_url.replace(/&hash=.*/, "");
        const receivedHash = rec_url.match(/&hash=(.*)/)[1];

        const calculatedHash = this.hash(urlWithoutHash);

        if (receivedHash === calculatedHash) {
          await FileSaver.saveAs(urlWithoutHash, id);
        } else {
          utilities.showToast(
            "You are not authroized to downolad this PDF ",
            true
          );
        }

        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        utilities.showToast(error, true);
      });
  };
  searchItems = () => {
    const { searchForDate, allOrders, showItemPerPage } = this.state;
    if (!searchForDate) {
      return this.setState({ searchFieldError: true });
    }
    const dateRange = this.convertDate(searchForDate);
    console.log("dateRange", dateRange);
    const results =
      allOrders &&
      allOrders.filter((d) => d.createdDate.slice(0, 10) === dateRange);
    if (results) {
      const total_rt = results.length;
      const totalPages = Math.ceil(total_rt / showItemPerPage);
      console.log("results", results);

      this.setState({
        allRecipts: results,
        loading: false,
        noOfOrders: total_rt,
        serverError: false,
        currentPage: 1,
        totalPages: totalPages,
        seachLoading: true,
      });
    }
  };

  changeSortParam = (e) => {
    const sortBy = e.currentTarget.value;
    this.setState({ sortParam: sortBy });
  };

  changeSortDirection = (e) => {
    const sortDirection = e.currentTarget.value;
    this.setState({ sortDirection: sortDirection });

    const sortedPages = this.state.allOrders.sort((a, b) => {
      if (sortDirection === "asc") {
        return new Date(a.createdDate) - new Date(b.createdDate);
      } else return new Date(b.createdDate) - new Date(a.createdDate);
    });

    this.setState({ allOrders: sortedPages });
  };

  onClickCloseBtn = () => {
    this.setState({ searchForDate: "", seachLoading: false });
    this.getOrders();
  };
  reloadPage = () => {
    this.getOrders();
  };
  onOrderpagesize = (e) => {
    const pageSize = e.target.value;
    this.setState({ showItemPerPage: pageSize });
  };

  render() {
    const {
      allOrders,
      currentPage,
      showItemPerPage,
      noOfOrders,
      searchFieldError,
      allRecipts,
      seachLoading,
    } = this.state;
  
    const indexOfLastPage = currentPage * showItemPerPage;
    const indexOfFirstPage = indexOfLastPage - showItemPerPage;
    const currentPages =
      allRecipts && allRecipts.slice(indexOfFirstPage, indexOfLastPage);
    const firstItem = indexOfFirstPage + 1;
    const lastItem = indexOfLastPage;

    return (
      <div className="page-wrap">
        <div className="page-header editOrderHeaderTop">
          <div className="editOrderHeaderTwo">
            <div className="sortFields">
              <div id="input_search">
                <span>Search</span>
                <DatePicker
                  showIcon
                  id="search_order_status"
                  placeholderText="Select date"
                  selected={this.state.searchForDate}
                  onChange={(date) => this.onDateChange(date)}
                  maxDate={new Date()}
                  minDate={new Date().setDate(new Date().getDate() - 60)}
                  onCalendarOpen={() =>
                    this.setState({ searchFieldError: false })
                  }
                />

                {!seachLoading
                  ? renderImages.getImage({
                      src: search_img,
                      className: "close_icon cursor_p",
                      onClick: this.searchItems,
                    })
                  : renderImages.getImage({
                      src: close_btn,
                      className: "close_icon cursor_p",
                      onClick: this.onClickCloseBtn,
                    })}
                {renderImages.getImage({
                  src: refresh_icon,
                  className: "refresh_icon_address cursor_p",
                  onClick: this.reloadPage,
                })}
              </div>
              <div className="sort_by mobile_sort">
                <span className="filter-label">Show</span>
                <select
                  value={showItemPerPage}
                  className="select-range combo"
                  style={{ marginRight: "0.5rem" }}
                  onChange={this.onOrderpagesize}
                >
                  <option className="SelectOption" value={50}>
                    50
                  </option>
                  <option className="SelectOption" value={100}>
                    100
                  </option>
                </select>

                <span className="filter-label">Sort By</span>
                <select
                  className="sort-criteria combo"
                  onChange={this.changeSortParam}
                  id="pl_dropdown"
                  style={{ width: "12.7rem" }}
                  value={this.state.sortParam}
                >
                  <option value="orderDate">MRI Document Date</option>
                  {/* <option value="invoiceNbr">Invoice #</option> */}
                </select>
                <select
                  className="sort-order combo"
                  onChange={this.changeSortDirection}
                  defaultValue="desc"
                  value={this.state.sortDirection}
                >
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending</option>
                </select>
              </div>
            </div>
            <div>
              {searchFieldError && (
                <div
                  style={{
                    marginLeft: "1rem",
                    color: "red",
                    fontSize: "14px",
                    marginTop: "-0.5rem",
                  }}
                >
                  Please select a date*
                </div>
              )}
            </div>
          </div>

          <div className="order-pagination">
            <span className="OrderTotalitem">
              {firstItem}-{lastItem} of {noOfOrders} orders
            </span>
            <span className="paginationAllorders">
              {this.state.totalPages > 0 && (
                <Pagination
                  className="pagination"
                  boundaryRange={0}
                  activePage={this.state.currentPage}
                  totalPages={this.state.totalPages}
                  onPageChange={this.onPageChange}
                  ellipsisItem={null}
                  siblingRange={1}
                  firstItem={
                    this.state.currentPage == 1
                      ? {
                          content: <span className="disabled">«</span>,
                        }
                      : { content: "«" }
                  }
                  lastItem={
                    this.state.currentPage == this.state.totalPages
                      ? {
                          content: <span className="disabled">»</span>,
                        }
                      : { content: "»" }
                  }
                  prevItem={
                    this.state.currentPage == 1
                      ? {
                          content: <span className="disabled">Prev</span>,
                        }
                      : { content: "Prev" }
                  }
                  nextItem={
                    this.state.currentPage == this.state.totalPages
                      ? {
                          content: <span className="disabled">Next</span>,
                        }
                      : { content: "Next" }
                  }
                />
              )}
            </span>
            <span className="order_count">Total {noOfOrders} Orders</span>
          </div>
        </div>
        {/* {this.props.multiStoreDetails.access === 1 ? (
          <h4>
            You don't have the permission to view this page.Please contact the
            True Value Administrator.
          </h4>
        ) : ( */}
        <>
          {this.state.loading && (
            <div>
              <Loader active />
            </div>
          )}

          <div className="allOrdersTable">
            <div className="eighteen wide column" id="allOrdersTable">
              <table
                className="ui celled table all-wh-table stickyTableEditOrder"
                id="font-size-orders"
              >
                <thead>
                  <tr>
                    <th
                      className="allOdrHeader"
                      id="no_border"
                      style={{ width: "1rem" }}
                    >
                      Index
                    </th>
                    <th className="allOdrHeader" id="no_border">
                      MRI Document Date
                    </th>
                    <th className="allOdrHeader" id="no_border">
                      MRI Document Number
                    </th>
                    <th className="allOdrHeader" id="no_border">
                      Download MRI PDF
                    </th>
                  </tr>
                </thead>
                <tbody style={{ overflowY: true }}>
                  {!this.state.noOfOrders || this.state.serverError ? (
                    <>
                      <tr>
                        <td colSpan="11" className="t-center">
                          No orders to display!
                        </td>
                      </tr>
                    </>
                  ) : (
                    currentPages.map((order, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td className="t-center" id="remove_border">
                              {index + 1}
                            </td>
                            <td className="t-center" id="remove_border">
                              {utilities
                                .hyphenForNull(order.createdDate)
                                .slice(0, 10) || "-"}
                            </td>
                            <td className="t-center" id="remove_border">
                              {utilities.hyphenForNull(order.invoiceNbr) || "-"}
                            </td>

                            <td
                              colSpan="1"
                              className="checkoutPointer"
                              id="remove_border"
                            >
                              <img
                                style={{ width: "20px" }}
                                src={DownloadReport}
                                alt="download"
                                onClick={() =>
                                  this.download_MRI_PDF(order.id)
                                }
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })
                  )}
                </tbody>
              </table>
              <div className="order-pagination">
                <span className="OrderTotalitem">
                  {firstItem}-{lastItem} of {noOfOrders} orders
                </span>
                <span className="paginationAllorders">
                  {this.state.totalPages > 0 && (
                    <Pagination
                      className="pagination"
                      boundaryRange={0}
                      activePage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.onPageChange}
                      ellipsisItem={null}
                      siblingRange={1}
                      firstItem={
                        this.state.currentPage == 1
                          ? { content: <span className="disabled">«</span> }
                          : { content: "«" }
                      }
                      lastItem={
                        this.state.currentPage === this.state.totalPages
                          ? { content: <span className="disabled">»</span> }
                          : { content: "»" }
                      }
                      prevItem={
                        this.state.currentPage == 1
                          ? {
                              content: <span className="disabled">Prev</span>,
                            }
                          : { content: "Prev" }
                      }
                      nextItem={
                        this.state.currentPage == this.state.totalPages
                          ? {
                              content: <span className="disabled">Next</span>,
                            }
                          : { content: "Next" }
                      }
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
        </>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  const storeId = state.SessionReducer.storeId;
  const userName = state.SessionReducer.userName;
  const userId = state.SessionReducer.UserId;
  const loggedInUser = state.SessionReducer.address.userName;
  // const loggedInUser = "demo";

  const rdcNum = state.SessionReducer.rdc;
  return {
    getMRIDocumentList: state.SessionReducer.getMRIDocumentList || [],
    getErrorMessage: state.SessionReducer.getErrorMessage || "",
    storeId: storeId,
    userName: userName,
    loggedInUser: loggedInUser,
    userId: userId,
    rdcNum,
    getprefer: "",
    viewOnly: state.SessionReducer.viewOnly,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    aliasSKURetailerGrpID: state.SessionReducer.address
      ? state.SessionReducer.address.aliasSKURetailerGrpID
      : "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return Object.assign({ dispatch }, bindActionCreators({ getMRIDocuments },dispatch));
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllOrders)
);

import React from 'react';
import {  Segment } from 'semantic-ui-react';
import tv_logo from "../views/images/Logo.svg.png";
import renderImages from "../views/common/RenderImages";
import { FOOTER_TV_LINK } from '../urlConstants';
import { footerContent , footerYearContent} from '../views/common/constants';

const Footer = () => (
  <Segment inverted vertical className="footer_wrap footerStyle">
  <div className="t-center">
    <div className="ui tiny image" id="img">{renderImages.getImage({src:tv_logo})}</div><span className="pipe">&nbsp;</span>
    {window.innerWidth > 600 &&
      <>
        <a href="https://aem.membersonline.com/content/MOL/terms-of-use.html" target="_blank" rel='noreferrer noopener'>
          <span className="link"> Terms Of Use</span>
        </a> 
        <span className="pipe">|</span>  
        <span className="link" to="">Privacy</span> <span className="pipe">|</span>   
        <a href="https://aem.membersonline.com/content/MOL/true-value-company/true-value-foundation.html" target="_blank" rel='noreferrer noopener'>
          <span className="link" >True Value Foundation</span>
        </a> 
        <span className="pipe">|</span>
        <a onClick= {() => window.open(FOOTER_TV_LINK(), '_blank')} rel='noreferrer noopener'>
          <span className="link">True Value Company</span>
        </a>
      </>
    }
    <p className="footer-content"> {footerContent} <span className="pipe"> | </span> {footerYearContent} </p>
  </div> 
  </Segment>
);

export default Footer;

import React, { Component } from "react";
import {
  Icon,
  Label,
  Dropdown,
  DropdownItem,
  Divider,
} from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import "./NavBar.css";
// import { userProfileMenu } from '../../constants/productMenu';
import { connect } from "react-redux";
import _ from "lodash";
import { bindActionCreators } from "redux";
// import { switchView } from './actions';
// import { logout } from '../../views/Login/actions';
// import { notifyCarts} from '../../views/Cart/actions';
// import { getFavoriteItems } from '../../views/Favorites/actions';
import tv_logo from "../views/images/Logo.svg.png";
import renderImages from "../views/common/RenderImages";
// import CartFlyout from '../../views/Cart/CartFlyout';
import { account, store, getRedirectUriMOL } from "../urlConstants";
// import { MEMBER_VIEW, CUSTOMER_VIEW } from '../NavBar/menuConstants';
import utilities from "../utils/utilities";
import history, { Event } from "../history";

export default class HeaderTop extends Component {
  constructor(props) {
    super(props);
    this.state = { showOptionsDiv: false, showProfileMenu: false };
  }
  redirectHome = () => {
      localStorage.clear();
    // this.props.history.push('/home');
      window.location.assign(getRedirectUriMOL());
  };
  redirectMol = () => {
    localStorage.clear();  
    window.location.assign(getRedirectUriMOL());
  };
  // Making the profile menu dropdown controlled one
  toggleProfileMenu = () => {
    const { showProfileMenu } = this.state;
    this.setState({
      showProfileMenu: !showProfileMenu,
    });
  };
  accountClick = () => {
    Event("HEADER", "Account Clicked");
    utilities.openInNewTab(account);
  };
  MouseEnterProfileMenu = () => {
    this.setState({
      showProfileMenu: true,
      showCartMenu: false,
    });
  };

  MouseLeaveProfileMenu = () => {
    this.setState({
      showProfileMenu: false,
    });
  };

  render() {
    console.log('this.props', this.props)
    return (
      <React.Fragment>
        <div
          className="header-top ui stackable grid zero-margin"
          computer={3}
          tablet={2}
        >
          <div className="header-top-left">
            <div className="header_Logo ">
              <div className="header_logo_img">
                {renderImages.getImage({
                  src: tv_logo,
                  align: "left",
                  alt: "truevalue",
                  className: "cursor_p",
                  onClick: this.redirectHome,
                })}
              </div>
              <div className="Netwarehouse">
                &nbsp;&nbsp;
                <span className="Netwarehouse_text">NETWAREHOUSE</span>
              </div>
            </div>
            <div className="mol" onClick={() => this.redirectMol()}>
              <i className="home icon clr_blk"></i>
              &nbsp;<span className="mol_text">Membersonline</span>
            </div>
          </div>
          <div className="header-top-filler">
            <div
              className="space_ref"
              onMouseEnter={this.MouseLeaveProfileMenu}
            ></div>
            <div
              className="space_ref_top"
              onMouseEnter={this.MouseLeaveProfileMenu}
            ></div>
          </div>
          <div className="header-top-filler">
            <div
              className="space_ref"
              onMouseEnter={this.MouseLeaveProfileMenu}
            ></div>
            <div
              className="space_ref_top"
              onMouseEnter={this.MouseLeaveProfileMenu}
            ></div>
          </div>
          <div className="header_icons header-top-right">
            <div className="header_user_icons_text">
              <span className="name_card">Hello, {this.props.userName}</span>
              <span className="Netwarehouse store_no clr_blk">
                {window.innerWidth >= 768 && <span>&nbsp;</span>}
                Store #{this.props.storeId}
                <span className="header-store-name">
                  ,&nbsp;{this.props.storeName}
                </span>
              </span>
            </div>
            <div className="header_user_icons">
              <Dropdown
                className="pointing top right"
                icon={
                  <i
                    title="User Profile"
                    className="square large user icon cursor_p"
                  />
                }
                onClick={this.toggleProfileMenu}
                onMouseEnter={this.MouseEnterProfileMenu}
                open={this.state.showProfileMenu}
              >
                <Dropdown.Menu
                  onMouseLeave={this.MouseLeaveProfileMenu}
                  className="user-menu"
                  id="user-menu"
                >
                  <div>
                    <DropdownItem className="user-menu-option">
                      Hello, {this.props.userName}
                    </DropdownItem>
                    <Divider className="user-menu-divider" />

                    {/* <DropdownItem
                    key="account"
                    onClick={this.accountClick}
                    className="user-menu-option"
                  >
                    Account
                  </DropdownItem> */}

                    <DropdownItem
                      key="redirectToMOL"
                      onClick={() => this.redirectMol()}
                      className="user-menu-option"
                    >
                      Return To MOL
                    </DropdownItem>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div>{this.props.content}</div>
      </React.Fragment>
    );
  }
}
